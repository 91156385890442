angular.module('fingerink')
    .factory('apikeysService', ['$http', 'session', function ($http, session) {
        var sdo = {
            getApiKeyAccount: function (params) {
                return $http({
                    method: 'GET',
                    url: 'apikey' + ((params != null) ? '?' + decodeURIComponent($.param(params)) : ""),
                    crossDomain: true
                });
            },
            
            getApiKey: function (params) {
                return $http({
                    method: 'GET',
                    url: 'apikey/key' + ((params != null) ? '?' + decodeURIComponent($.param(params)) : ""),
                    crossDomain: true
                });
            },  
            
            
            addApiKeyAccount: function (user) {
                return $http({
                    method: 'POST',
                    url: 'apikey',
                    crossDomain: true,
                    data: user
                });
            },
            
            updatePassword: function (user,password) {
                return $http({
                    method: 'POST',
                    url: 'apikey/changepassword/' + user.id,
                    crossDomain: true,
                    data: password
                });
            },            
            
            deleteApiKeyAccount: function (id_api,id_usuario) {
                return $http({
                    method: 'DELETE',
                    url: 'apikey/' + id_api+ '/' + id_usuario,
                    crossDomain: true
                });
            }             
            

        };
        return sdo;
}]);
